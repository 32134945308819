.button
{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: .5rem 1.3rem .5rem 1.3rem;
    border-radius: 2rem;
    gap: 10px;

    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;

    transition: all .5s;

    min-width: fit-content;

    border: 2px solid;

    text-decoration: none;
}

.button svg
{
    width: 20px;
    height: 20px;
}

.btn_mini
{
    padding: .5rem;
}

.disabled
{
    background: var(--btnColorDisabled);
    border-color: var(--btnColorDisabled);

    cursor: default;
}

.primary, .ghost:hover
{
    background: var(--btnColor);
    color: #fff;

    border-color: var(--btnColor);
}

.primary:hover, .secondary:hover
{
    background: var(--btnColor);
    border-color: var(--btnColor);
    color: #fff;
}

.secondary
{
    border-color: var(--btnColor);
    color: var(--btnColor);
}

.ghost
{
    border-color: transparent;
    color: #fff;
}

.text
{
    display: flex;
    align-items: center;
    text-align: center;
}

.icon
{
    display: flex;
    align-items: center;
}

.danger
{
    background: var(--error);
    border-color: var(--error);

    color: #fff;
}

.danger:hover, .danger_disabled
{
    background: var(--errorHover);
    border-color: var(--errorHover);
}

.danger_disabled
{
    color: rgba(255, 255, 255, 0.7);
}
