.reservation_container
{
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 10px;
}

.filter_controls
{
    display: flex;
    justify-content: flex-end;

    gap: 10px;
}

.verify_filter
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.delete_controls
{
    display: flex;
    justify-content: flex-end;
}

.reservation_container table
{
    text-align: center;
    border-collapse: collapse;
    width: 100%;
}

.reservation_container table thead
{
    background: #a6a6a6;
}

.action_col
{
    display: flex;
    align-items: center;
}

.action_col div
{
    display: flex;
    width: 100%;
    justify-content: center;
}

.reservation_container table thead th
{
    width: 500px;
    cursor: pointer;

    user-select: none;
}

.reservation_container table thead th div
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.reservation_container table thead th svg
{
    width: 20px;
    height: 20px;
}

.state
{
    border: 2px solid transparent;

    text-align: center;

    padding: 2px;
    box-sizing: border-box;

    border-radius: 5px;

    color: #fff;
    font-weight: 600;
}

.rejected
{
    background: var(--error);
    border-color: var(--error);
}

.pending
{
    background: var(--warning);
    border-color: var(--warning);
}

.accepted
{
    background: var(--success);
    border-color: var(--success);
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .reservation_container table thead
    {
        display: none;
    }

    .reservation_container table tbody tr
    {
        display: flex;
        flex-direction: column;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        gap: 6px;
    }

    .reservation_container table tbody tr:nth-child(even)
    {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .reservation_container table tbody td
    {
        font-size: .8em;
        text-align: right;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .reservation_container table tbody td::before
    {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .action_col div
    {
        justify-content: flex-end;
    }

    .filter_controls
    {
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .reservation_container table thead
    {
        width: 100%;
    }
}
