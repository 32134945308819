.area_content
{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 15px;

    text-align: center;

    width: 1200px;
    height: 80vh;
    max-height: 860px;

    overflow-y: scroll;

    position: relative;

    box-sizing: border-box;
}

.area_modify_container
{
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-bottom: 50px;
}

.header
{
    font-weight: bold;
}

.area_controls
{
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    width: 100%;

    position: fixed;

    bottom: 0;
    right: 0;

    padding: 10px;

    background: #fff;
}

.area_form
{
    display: flex;
    text-align: left;
    justify-content: center;

    gap: 10px;
}

.area_form label
{
    display: flex;
    flex-direction: column;
}

.inputs
{
    display: flex;
    flex-direction: column;
}

.area_form input, .area_form textarea
{
    font-size: 16px;
}

.area_form input
{
    width: 250px;
}

.area_form textarea
{
    width: 350px;
    height: 150px;

    resize: none;
}

@media only screen and (max-width: 1460px), (min-device-width: 768px) and (max-device-width: 1460px)
{
    .area_content
    {
        width: 100vw;
        height: 90vh;
    }

    .area_form
    {
        flex-direction: row;
    }

}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .area_content
    {
        width: 100vw;
        height: 80vh;
    }

    .area_modify_container
    {
        height: 100%;

        overflow-y: scroll;
    }

    .area_form
    {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .area_form textarea
    {
        width: 250px;
    }

    .area_controls
    {
        justify-content: center;
    }
}
