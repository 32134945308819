.notifications_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    gap: 10px;
}

.notification_form
{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 500px;

    gap: 10px;

}
.notification_form label
{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    gap: 5px;
}

.notification_form input, .notification_form textarea
{
    font-size: 16px;
}

.notification_form textarea
{
    min-height: 200px;
    padding: 10px;
    resize: vertical;
}


.notifications_controls
{
    display: flex;
    gap: 20px;
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .notification_form
    {
        width: 90vw;
    }

    .notifications_controls
    {
        flex-direction: column;
    }
}
