.area_controls_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    gap: 50px;
}

.card_lists
{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;
}

.list_controls
{
    display: flex;
    gap: 10px;
}

.area_container, .space_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    gap: 10px;
}

.search
{
    display: flex;
    width: 350px;
}

.area_controls
{
    display: flex;
    width: 100%;
}

.new_area_controls, .capacity_controls
{
    width: 50%;
}

.new_area_controls
{
    display: flex;

    align-items: center;
    justify-content: flex-end;
}

.area_space_controls
{
    display: flex;
    gap: 10px;
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .search
    {
        width: 100%;
    }
}
