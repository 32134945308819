.additional_info_container
{
    display: flex;
    flex-direction: column;

    border-radius: 10px;
    height: 80vh;
    max-height: 705px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.map_container
{
    width: 100%;
    height: 400px;

    border-radius: 10px 10px 0 0;
}

.additional_info_content
{
    display: flex;
    flex-direction: column;

    width: 700px;
    height: 250px;

    gap: 10px;

    padding: 25px 25px 50px 25px;

    font-weight: 400;
    font-size: 18px;


    position: relative;
}


.header
{
    font-size: 26px;
}

.big_text
{
    text-align: left;

    min-height: 50px;
    height: fit-content;
    max-height: 150px;
    overflow-y: scroll;

    margin: 0;
}

.additional_info_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;

    position: fixed;
    padding: 10px;
    bottom: 0;
    left: 0;
    right: 0;

    background: #fff;
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .additional_info_container
    {
        width: 100vw;
        height: 100vh;
    }

    .additional_info_container_single
    {
        width: 100vw;
    }

    .map_container
    {
        width: 100%;
        height: 60%;
    }

    .additional_info_content
    {
        width: 100%;
        height: 40%;

        padding: 10px;

        box-sizing: border-box;
    }

    .big_text
    {
        font-size: 2vh;
        min-height: 35px;
    }

}

