@import 'helpers/Colors.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container
{
  width: 1470px;
  padding: 10px;
}

select
{

  border-radius: 5px;
  background: transparent;
  padding: 8px;

  border: 2px solid var(--borderGrey);

  cursor: pointer;
}

select:focus
{
  border-color: var(--btnColor);
}

input, textarea
{
  border-radius: 10px;
  padding: 10px;
  border: 2px solid var(--btnColor);
  outline: none;
}

input:focus, textarea:focus
{
  border-color: var(--btnColor);
}

.react-datepicker-wrapper
{
  display: block !important;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
  .main-container
  {
    width: 100%;
    box-sizing: border-box;
  }
}
