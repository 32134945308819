.page_controls_container
{
    display: flex;
    align-items: center;
    gap: 25px;
}

.items_controls
{
    appearance: none;

    width: 100px;
    padding: 10px;

    border: 1px solid #1e5c1c;
    border-radius: 2em;

    background-color: #fff;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: right;
    background-repeat: no-repeat;

    cursor: pointer;
}

.page_controls
{
    display: flex;

    justify-content: center;
    align-items: center;
    text-align: center;

    gap: 5px;
}

.page_controls button
{
    all: unset;

    display: flex;
    align-items: center;

    height: 100%;

    cursor: pointer;
}

.page_controls button:hover
{
    color: #1e5c1c;
}

.page_controls svg
{
    width: 30px;
    height: 30px;
}
