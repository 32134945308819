.new_space_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.card_style
{
    flex: none;
    width: 270px;
}

.space_add_modify_container
{
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 10px;
}

.header
{
    font-weight: bold;
}

.spaces_list
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding: 10px;
}

.space_controls
{
    display: flex;

    gap: 10px;
}

.spaces_header
{
    display: flex;
    justify-content: center;

    gap: 10px;
}

.new_space_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.space_form
{
    display: flex;
    flex-direction: column;
}

.space_form label
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    text-align: left;
}

.space_form label span
{
    display: flex;
    align-items: center;
    gap: 10px;
}

.space_form input[type="checkbox"]
{
    all: unset;
    margin-top: 10px;
}

.space_form input[type="checkbox"]::before
{
    content: '';
    display: inline-flex;
    width: 1em;
    height: 1em;

    border-radius: 10px;
    border: 2px solid var(--btnColor);
}

.space_form input[type="checkbox"]:checked::before
{
    clip-path: polygon(14% 44%,0 65%,50% 100%,100% 16%,80% 0,43% 62%);
    background-color: var(--btnColor);
}

.space_form input[type="checkbox"]:disabled::before
{
    background-color: #707070;
}

.space_form input, .space_form textarea
{
    width: 350px;

    font-size: 16px;
}

.space_form textarea
{
    height: 100px;
    resize: none;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .space_form
    {
        width: 90vw;
        align-items: center;
    }

    .space_form input, .space_form textarea
    {
        width: 250px;
    }
}
