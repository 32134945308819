.statistic_container
{
    display: flex;
    flex-direction: column;

    gap: 50px;

    width: 100%;
    max-width: 100%;
}

.statistic_controls
{
    display: flex;
    justify-content: center;

    width: 100%;
    gap: 10px;
}

.statistics_content
{
    display: flex;
    justify-content: center;
}
