.screen_user_controls
{
    display: flex;
    gap: 10px;
}

.screen_content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 650px;
    max-height: 90vh;

    gap: 10px;

    padding: 10px;
    text-align: center;

    box-sizing: border-box;
}

.screen_content h2
{
    padding: 0;
    margin: 0;
}

.screen_content span
{
    white-space: pre-line;
    word-break: break-word;

    max-height: 350px;
    overflow-y: scroll;
    padding: 10px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .screen_content
    {
        width: 100vw;
    }

}
