.last_reserved_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    padding: 10px;

    box-sizing: border-box;

    max-width: 700px;

    gap: 10px;
}

.last_reserved_container h1, .last_reserved_container h3
{
    padding: 0;
    margin: 0;
}

.last_reserved_container h3
{
    word-break: break-word;
}

.last_reservation_info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 10px;
}

.reserve_again_container
{
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 10px;
}

.reserve_again_controls
{
    display: flex;
    gap: 10px;
}

.reserve_again_btn
{
    display: flex;
    align-items: center;
}

.spaces_list
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 10px;

    width: 100%;

    max-height: 350px;

    overflow-y: scroll;

    padding: 10px;
    box-sizing: border-box;
}

.reserve_again_selection
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    width: 100%;
}

.reserve_again_selection_controls
{
    display: flex;
    gap: 10px;
}

.date_pickers
{
    display: flex;
}



@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .last_reserved_container
    {
        width: 100vw;
    }

    .last_reserved_container h1
    {
        font-size: 36px;
    }

    .reserve_again_selection
    {
        max-height: 80vh;
    }

    .date_pickers
    {
        flex-direction: column;
    }
}
