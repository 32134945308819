.control_panel_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 50px;

    width: 100%;
}

.control_panel_controls
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.control_panel_option_container
{
    display: flex;

    width: 100%;
}
