.container
{
    display: flex;
    flex-direction: column;

/*    width: 100%;*/

    height: 80vh;
    max-height: 830px;

    overflow-y: scroll;

    gap: 10px;

    padding: 10px;
}

.header
{
    text-align: center;
    font-weight: 600;
    font-size: 32px;
}

.controls
{
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: center;

    position: relative;
}

.container table
{
    display: flex;
    flex-direction: column;

    text-align: center;
    height: 100%;

    overflow: hidden;
}

.container thead
{
    display: block;

    width: 100%;

    background: #a6a6a6;
}

.container thead tr
{
    display: flex;
}

.container thead th
{
    flex: 1;
}

.container tbody
{
    display: flex;
    flex-direction: column;

    flex: 1;
    overflow-y: scroll;

    gap: 10px;

    padding: 10px;
}

.container tbody tr
{
    display: flex;
    align-items: center;

    border-radius: .5rem;
    box-shadow: 0 0 10px rgba(212,216,232,.6);

    padding: 5px;
}

.container tbody td
{
    flex: 1;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .controls
    {
        flex-wrap: wrap;
    }

    .container thead
    {
        display: none;
    }

    .container tbody tr
    {
        display: flex;
        flex-direction: column;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .container tbody td
    {
        font-size: .8em;
    }

    .container tbody td::before
    {
        content: attr(data-label);
        font-weight: bold;
    }

    .container
    {
        max-height: 80vh;
    }

}
