.reservation_info
{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;

    text-align: center;

    gap: 5px;
}

.reservation_info h1
{
    white-space: pre-line;
    word-break: break-word;

    padding: 0;
    margin: 0;

    font-size: 36px;
}

.found_reservations_list
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 600px;
    min-height: 200px;

    overflow-y: scroll;
}

.card
{
    height: fit-content;
    width: 270px;
    flex: unset;
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .found_reservations_list
    {
        width: fit-content;
        height: 50vh;
    }
}
