.date_picker
{
    font-size: 1rem;

    border-radius: 5px;
    padding: 8px;

    border: 2px solid var(--borderGrey);
    cursor: pointer;

    outline: none;
}

.date_picker:focus
{
    border: 2px solid var(--btnColor);
}
