.user_main_container
{
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 50px;
}

.reservations_container, .owned_space_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    gap: 10px;
}

.reservations_list
{
    height: 50vh;
    overflow-y: scroll;
}

.reservations_list, .owned_space_list
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;

    padding: 10px;
}

.user_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;
}

@media only screen and (max-width: 1470px), (min-device-width: 768px) and (max-device-width: 1470px)
{
    .reservations_list
    {
        min-height: 60vh;
    }
}
