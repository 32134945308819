.loader_content
{
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    font-size: 16px;

    padding: 10px;
}
