.card_container, .card_container_default
{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: calc(20% - 8px);
    min-height: 180px;
    height: fit-content;

    flex: 0 1 calc(20% - 8px);

    padding: 1rem;

    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    cursor: pointer;

    gap: 16px;

    font-size: 16px;

    box-sizing: border-box;
}

.header
{
    font-weight: 600;
    font-size: 32px;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    line-height: normal !important;

    white-space: nowrap;
}

.card_info, .card_info_default
{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    gap: 5px;

    height: 100%;

    line-height: 150%;
}

.card_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;

    font-weight: 600;
    color: var(--btnColor);
}

.card_controls:hover
{
    color: var(--btnColor);
}

.card_container:hover, .card_container_default:hover
{
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.card_selected
{
    background: linear-gradient(180deg, #DAF1D9 0%, rgba(218, 241, 217, 0) 100%) !important;
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.disabled
{
    background: var(--borderGrey);

    cursor: default;
}

.disabled:hover
{
    border-color: transparent;
    box-shadow: none;
}

.card_type_container
{
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
}

.card_type_container span svg
{
    width: 18px;
    height: 18px;
}

.card_type_container span
{
    display: flex;
    align-items: center;
}

.card_type_container span svg:hover
{
    fill: var(--btnColor)
}

.type_info_container
{
    position: absolute;
    bottom: 30px;

    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    padding: 4px;
    box-sizing: border-box;

    width: 250px;

    z-index: 2;
}

.type_info_left
{
    left: 75px;
}

.type_info_right
{
    right: 0;
}

.status span
{
    border: 2px solid transparent;

    text-align: center;

    padding: 2px;
    box-sizing: border-box;

    border-radius: 5px;

    color: #fff;
    font-weight: 600;
}

.rejected
{
    background: linear-gradient(180deg, var(--error) 25%, rgba(218, 241, 217, 0) 70%);
}

.pending
{
    background: linear-gradient(180deg, var(--warning) 25%, rgba(218, 241, 217, 0) 70%);
}

.accepted
{
    background: linear-gradient(180deg, var(--success) 25%, rgba(218, 241, 217, 0) 70%);
}

.type_info
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.type_header
{
    text-align: center;
    font-weight: 600;
}

.general_info
{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
}

@media only screen and (max-width: 1319px), (min-device-width: 768px) and (max-device-width: 1319px)
{
    .card_container, .card_container_default
    {
        flex: 0 0 calc(25% - 7.5px);
        width: calc(25% - 7.5px);
    }

}

@media only screen and (max-width: 1054px), (min-device-width: 768px) and (max-device-width: 1054px)
{
    .card_container, .card_container_default
    {
        flex: 0 0 calc(33% - 5px);
        width: calc(33% - 5px);
    }

    .card_info, .card_info_default
    {
        height: 100%;
    }
}

@media only screen and (max-width: 809px), (min-device-width: 768px) and (max-device-width: 809px)
{
    .card_container
    {
        flex: 0 1 calc(50% - 5px);
        width: calc(50% - 5px);
    }

    .card_container_default
    {
        flex: initial;
        width: 100%;
    }

    .card_info
    {
        height: 15rem;
    }

    .card_type_container span svg
    {
        width: 21px;
        height: 21px;
    }

    .card_container > .card_controls
    {
        flex-direction: column;
        align-items: center;

        text-align: center;
    }

    .control_panel_spaces
    {
        min-height: 320px;
    }
}
