.window_container
{
    display: flex;
    position: fixed;

    z-index: 5;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0,0,0,.5);
}

.window_container_content
{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

/*    gap: 5px;*/

    border-radius: 10px;

    padding: 10px;

    overscroll-behavior: contain;
    overflow: auto;
    touch-action: none;
}

.window_container_content
{
    height: fit-content;
}

.info_content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: #fff;
    border-radius: 10px;

    font-size: 1.5rem;

    overflow: hidden;
}

.control_btn
{
    all: unset;

    display: flex;
    align-items: center;
    height: 100%;

    cursor: pointer;
}

.control_btn:hover svg, .control_btn:disabled svg
{
    color: #a6a6a6;
}

.control_btn svg
{
    width: 30px;
    height: 30px;

    color: #fff;
}
