.container
{
    display: flex;
    flex-direction: column;

    width: 1000px;
    height: 100%;

    gap: 10px;
}

.container table
{
    width: 100% !important;
    min-height: 250px;
}

.container tbody
{
    max-height: 50vh;
}

.container tbody tr
{
    border: 2px solid var(--borderGrey);
}

.controls
{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 10px;

    width: 100%;
}

.pending_controls
{
    display: flex;
    justify-content: center;

    width: 100%;
    gap: 10px;
}

.selected
{
    background: linear-gradient(180deg, #DAF1D9 0%, rgba(218, 241, 217, 0) 100%);
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor) !important;
}

.sort_by
{
    display: flex;
    justify-content: flex-end;

    width: 100%;
}

.sort_by label
{
    display: flex;
    flex-direction: column;

    font-size: 16px;
    gap: 5px;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .container
    {
        width: 100%;
    }
}
