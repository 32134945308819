.owned_spaces_stats_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    width: 100%;
}

.owned_spaces_stats_list
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    gap: 10px;
}

.stats_date_filter
{
    display: flex;
    align-items: center;
    gap: 5px;
}
@media only screen and (max-width: 530px), (min-device-width: 768px) and (max-device-width: 530px)
{
    .proper_mobile_height
    {
        height: 300px;
    }
}
