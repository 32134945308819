.release_main_container
{
    display: flex;
    flex-direction: column;

    width: 1100px;
    height: 700px;

    text-align: center;
}

.release_container
{
    display: flex;
    width: 100%;
    height: 90%;
    gap: 10px;

    overflow-y: scroll;
}

.release_period_controls
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 30%;

    gap: 10px;
}

.release_period_controls a
{
    width: 250px;
}

.release_dates_container
{
    display: flex;
    flex-direction: column;
    width: 70%;

    gap: 10px;

    border-left: 1px solid;
}

.release_date_tr_new
{
    background: rgba(0, 0, 0, 0.1);
}

.card_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.release_controls
{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;

    height: 10%;
}

.advanced_options
{
    display: flex;
    align-items: center;
    width: 100%;
}
.advanced_options hr
{
    flex-grow: 1;
    height: 0;
}

.header
{
    font-size: 24px ;
    font-weight: bold;
}

.custom_dates_controllers
{
    display: flex;
    width: 100%;
}

.custom_dates_controllers label
{
    width: 50%;
}

.custom_dates_controllers input
{
    width: 130px;
}

@media only screen and (max-width: 1100px), (min-device-width: 768px) and (max-device-width: 1100px)
{
    .release_main_container
    {
        width: 95vw;
    }
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .release_main_container
    {
        height: 75vh;
        overflow-y: scroll;
    }

    .release_container
    {
        flex-direction: column;
    }

    .release_period_controls
    {
        width: 100%;
    }

    .release_controls
    {
        justify-content: center;
    }

    .release_dates_container
    {
        border: none;
    }

    .release_dates_container, .release_period_controls
    {
        width: 100%;
    }
}
