.booking_main_container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.booking_container
{
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 10px;
}

.booking_controls_info
{
    display: flex;
    flex-direction: column;

    gap: 50px;
}

.reservations_controls
{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.options_controls
{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 5px;
}

.dates_controls
{
    display: flex;
    align-items: center;
}

.custom_dates_controllers
{
    display: flex;
    width: 50%;

    gap: 10px;
}

.custom_dates_controllers label
{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.custom_dates_controllers label span
{
    margin-bottom: 5px;
}

.custom_dates_controllers input
{
    width: 90%;
}

.presets_dates_container
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 50%;
}

.presets_dates
{
    display: flex;
    gap: 5px;
}

.presets_dates a
{
    width: fit-content;
    padding: .5rem 1.3rem .5rem 1.3rem;

    font-weight: 700;
}

.spaces_list
{
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    width: 100%;
}

.user_options
{
    display: flex;
    gap: 10px;
}

.first_visit_container
{
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    word-break: break-all;
}

.first_visit_header
{
    font-weight: bold;

    font-size: 24px;
}

.first_visit_city, .first_visit_charging
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.first_visit_charging_controls
{
    display: flex;
    gap: 10px;
}

.first_visit_container h1
{
    word-break: break-word;
}

.pre_list_container
{
    display: flex;
    width: 100%;
}

.spaces_reservation_title
{
    display: flex;
    align-items: center;
    gap: 30px;
    width: 65%;

    font-size: 30px;
}

.spaces_num
{
    font-weight: 600;
}

.reservation_controls
{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    gap: 20px;
    width: 35%;
}

.owned_space_release_message
{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;

    text-align: center;

    gap: 10px;
}

.filter_option
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.filter_option label
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;
}

.search_container
{
    display: flex;
    align-items: flex-end;
}

.search_container > .search
{
    width: 100%;
}

.options_header
{
    font-weight: 600;
}

.advanced_options
{
    display: none;
    align-items: center;
    width: 100%;

    gap: 5px;
    color: var(--btnColor);
    cursor: pointer;

    font-size: 15px;
    font-weight: 600;

}
.advanced_options hr
{
    flex-grow: 1;
    height: 0;

    color: var(--borderGrey);
}

.notification_button
{
    display: flex;
    justify-content: center;
}

.filters_content
{
    display: flex;

    gap: 10px;
}

.bottom_menu
{
    display: flex;
    flex-direction: column;
    position: absolute;

    top: 70px;
    gap: 5px;
}

.notification_explainer_container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.type_info_container
{
    position: absolute;
    top: 30px;
    left: 0;

    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    padding: 4px;
    box-sizing: border-box;

    width: 250px;
}

.type_info
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.custom_time_container
{
    display: none;
}


@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{

    .booking_controls_info
    {
        gap: 15px;
    }

    .reservations_controls
    {
        flex-direction: column;
    }

    .options_controls
    {
        width: 100%;
    }

    .pre_list_container, .user_options
    {
        flex-direction: column;

        width: 100%;
    }

    .filters_content
    {
        width: 100%;
    }

    .filter_option
    {
        flex: 1;
    }

    .filter_option select
    {
        width: 100%;
    }

    .presets_dates_container, .dates_controls
    {
        width: 100%;
    }

    .dates_controls
    {
        flex-direction: column;
        gap: 10px;
    }

    .custom_dates_controllers
    {
        width: 100%;
    }

    .custom_dates_controllers .react-datepicker-wrapper
    {
        width: 50% !important;
    }

    .custom_dates_controllers .react-datepicker-portal
    {
        background-color: white !important;
    }

    .presets_dates a
    {
        flex: 1;
    }

    .advanced_options
    {
        display: flex;
        margin-bottom: 20px;
    }

    .pre_list_container
    {
        gap: 10px;
    }

    .spaces_reservation_title
    {
        flex-direction: column;
        text-align: center;

        width: 100%;

        font-size: 16px;

        gap: 5px;
    }

    .spaces_num
    {
        font-size: 21px;
    }

    .reservation_controls
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 100%;
    }

    .reservation_controls span
    {
        order: 2;
    }

    .bottom_menu
    {
        justify-content: center;

        width: 100%;
        height: 70px;
        bottom: 0;
        left: 0;
        top: unset;

        position: fixed;

        background: #fff;
        box-shadow: 0 0 20px rgba(212,216,232);
        padding: 10px;

        box-sizing: border-box;

        z-index: 2;
    }

    .bottom_menu a
    {
        padding: 5px;
    }

    .type_info_container
    {
        position: absolute;
        top: unset;
        bottom: 130px;
        left: 50%;
        right: 50%;
        transform: translate(-50%, 50%);
    }

    .search_container
    {
        flex: 1;
    }

    .custom_time_container
    {
        display: flex;
        justify-content: center;
    }
}
