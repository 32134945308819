.stats_per_area_spaces_container
{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
}

.stats_per_area_spaces_controls
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stats_per_area_spaces_controls label
{
    display: flex;
    gap: 5px;
    align-items: center;
}
